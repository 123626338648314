import React, { useState, useEffect } from 'react';
import { __ } from '@wordpress/i18n';
import { uniq, is, isEmpty } from 'ramda';

// tools
import getBandoLabel from '../../../../helpers/getBandoLabel';
import getBandoSeverity from '../../../../helpers/getBandoSeverity';

// store
import { useStore } from '../../../../store';

// api
import ApplicationService from '../../../../service/application-service';

// components
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';
import { ProgressBar } from 'primereact/progressbar';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Tag } from 'primereact/tag';
import ProperBandoLabel from '../../../../components/ProperBandoLabel';
import { Link } from 'react-router-dom';
import translationStrings from '../../../../translationStringsForComponents';
import { ConfirmPopup, confirmPopup } from 'primereact/confirmpopup';

const MyLatestSubmissionsTable = () => {
    const chosenCompanyId = useStore().main.chosenCompanyId();
    const [localAsyncRequest, setLocalAsyncRequest] = useState(false);
    const [items, setItems] = useState(null);
    const [filters, setFilters] = useState(null);
    const [statuses, setStatuses] = useState([]);

    useEffect(() => {
        if (!isEmpty(chosenCompanyId) && chosenCompanyId !== 0) {
            setLocalAsyncRequest(true);
            ApplicationService.getApplications(getApplCallback, errGetApplCallback, [
                ['companyId', chosenCompanyId],
                ['statuses', ['DRAFT', 'AWAITING', 'READY']]
            ]);
        }
    }, [chosenCompanyId]);

    const getApplCallback = (data) => {
        if (data.status === 'SUCCESS') {
            if (is(Array, data.data)) {
                setItems(getFormattedBandiData(data.data));
                setStatuses(uniq(items.map(o => o.status)))
                initFilters();
            }
        }
        setLocalAsyncRequest(false);
    }

    const errGetApplCallback = (data) => {
        setLocalAsyncRequest(false);
    }

    const handleDeleteApplication = (id) => {
        setLocalAsyncRequest(true);
        ApplicationService.deleteApplication(id, (resp) => delApplCallback(resp, id), errDelApplCallback)
    }

    const delApplCallback = (resp, id) => {
        if (resp.status === 'SUCCESS') {
            const newItems = items.filter(o => o.id !== id);
            setItems(newItems);
        }
        setLocalAsyncRequest(false);
    }

    const errDelApplCallback = (data) => {
        setLocalAsyncRequest(false);
    }

    const getFormattedBandiData = (data) => {
        return [...(data || [])].map((d) => {
            d.callEndDate = new Date(d.callEndDate);
            d.modifiedDate = new Date(d.modifiedDate);

            return d;
        });
    };

    const formatDate = (value) => {
        return value.toLocaleDateString('it-IT', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        });
    };

    const clearFilter = () => {
        initFilters();
    };

    const initFilters = () => {
        setFilters({
            global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            callTitle: {
                operator: FilterOperator.AND,
                constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]
            },
            companyName: {
                operator: FilterOperator.AND,
                constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]
            },
            modifiedDate: {
                operator: FilterOperator.AND,
                constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }]
            },
            callEndDate: {
                operator: FilterOperator.AND,
                constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }]
            },
            status: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
        });
    };

    const renderHeader = () => {
        return (
            <div className="appTableHeader">
                <Button type="button" icon="pi pi-filter-slash" label={__('Pulisci', 'gepafin')} outlined
                        onClick={clearFilter}/>
            </div>
        );
    };

    const dateModifyBodyTemplate = (rowData) => {
        return formatDate(rowData.modifiedDate);
    };

    const dateEndBodyTemplate = (rowData) => {
        return formatDate(rowData.callEndDate);
    };

    const dateFilterTemplate = (options) => {
        return <Calendar value={options.value} onChange={(e) => options.filterCallback(e.value, options.index)}
                         dateFormat="mm/dd/yy" placeholder="mm/dd/yyyy" mask="99/99/9999"/>;
    };

    const statusBodyTemplate = (rowData) => {
        return <ProperBandoLabel status={rowData.status}/>;
    };

    const statusFilterTemplate = (options) => {
        return <Dropdown value={options.value} options={statuses}
                         onChange={(e) => options.filterCallback(e.value, options.index)}
                         itemTemplate={statusItemTemplate} placeholder={translationStrings.selectOneLabel}
                         className="p-column-filter"
                         showClear/>;
    };

    const progressBodyTemplate = (options) => {
        return <ProgressBar value={options.progress} color={'#64748B'}></ProgressBar>;
    };

    const statusItemTemplate = (option) => {
        return <Tag value={getBandoLabel(option)} severity={getBandoSeverity(option)}/>;
    };

    const actionsBodyTemplate = (rowData) => {
        return 'DRAFT' === rowData.status
            ? <div className="appPageSection__tableActions lessGap">
                <Link to={`/imieibandi/${rowData.id}`}>
                    <Button severity="info" label={__('Modifica', 'gepafin')} icon="pi pi-pencil" size="small"
                            iconPos="right"/>
                </Link>
                <ConfirmPopup/>
                <Button severity="danger"
                        /*onClick={() => handleDeleteApplication(rowData.id)}*/
                    onClick={(event) => confirmDelete(event, rowData.id)}
                        label={__('Cancella', 'gepafin')}
                        icon="pi pi-trash"
                        size="small"
                        iconPos="right"/>
            </div>
            : <Link to={`/imieibandi/${rowData.id}`}>
                <Button severity="info" label={__('Mostra', 'gepafin')} icon="pi pi-eye" size="small"
                        iconPos="right"/>
            </Link>

    }

    const header = renderHeader();

    const confirmDelete = (event, id) => {
        confirmPopup({
            target: event.currentTarget,
            message: __('Sei sicuro di voler rimuovere la domanda?', 'gepafin'),
            acceptLabel: __('Si', 'gepafin'),
            icon: 'pi pi-info-circle',
            defaultFocus: 'reject',
            acceptClassName: 'p-button-danger',
            accept: () => {
                handleDeleteApplication(id);
            },
            reject: () => {
            }
        });
    };

    return (
        <div className="appPageSection__table">
            <DataTable value={items} paginator showGridlines rows={5} loading={localAsyncRequest} dataKey="id"
                       filters={filters} stripedRows removableSort
                       header={header}
                       emptyMessage={translationStrings.emptyMessage}
                       onFilter={(e) => setFilters(e.filters)}>
                <Column field="callTitle" header={__('Bando', 'gepafin')}
                        filter sortable
                        filterPlaceholder={__('Cerca il nome', 'gepafin')}
                        style={{ minWidth: '8rem' }}/>
                <Column field="companyName" header={__('Azienda', 'gepafin')}
                        filter sortable
                        filterPlaceholder={__('Cerca il nome', 'gepafin')}
                        style={{ minWidth: '8rem' }}/>
                <Column header={__('Scadenza', 'gepafin')} filterField="callEndDate" dataType="date"
                        style={{ minWidth: '8rem' }}
                        body={dateEndBodyTemplate}
                        filter
                        filterElement={dateFilterTemplate}/>
                <Column header={__('Ultima modifica', 'gepafin')} filterField="modifiedDate" dataType="date"
                        style={{ minWidth: '8rem' }}
                        body={dateModifyBodyTemplate}
                        filter
                        filterElement={dateFilterTemplate}/>
                <Column field="status" header={__('Stato', 'gepafin')} filterMenuStyle={{ width: '14rem' }}
                        style={{ minWidth: '7rem' }} body={statusBodyTemplate}
                        filterElement={statusFilterTemplate}/>
                <Column header={__('Progressi', 'gepafin')}
                        style={{ minWidth: '10rem' }} field="progress" body={progressBodyTemplate}/>
                <Column header={__('Azioni', 'gepafin')}
                        style={{ minWidth: '10rem' }}
                        body={actionsBodyTemplate}/>
            </DataTable>
        </div>
    )
}

export default MyLatestSubmissionsTable;
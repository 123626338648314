import { NetworkService } from './network-service';

const API_BASE_URL = process.env.REACT_APP_API_EXECUTION_ADDRESS;

export default class NotificationService {

    /*static getNotifications = (id, callback, errCallback, queryParams) => {
        NetworkService.get(`${API_BASE_URL}/notification/user/${id}`, callback, errCallback, queryParams);
    };*/

    static getNotificationsByCompanyId = (id, companyId, callback, errCallback, queryParams) => {
        NetworkService.get(`${API_BASE_URL}/notification/user/${id}/company/${companyId}/notifications`, callback, errCallback, queryParams);
    };

    static getNotificationsPagination = (id, body, callback, errCallback, queryParams) => {
        NetworkService.post(`${API_BASE_URL}/notification/user/${id}/pagination`, body, callback, errCallback, queryParams);
    };

    static notificationMakeRead = (id, callback, errCallback) => {
        NetworkService.put(`${API_BASE_URL}/notification/${id}`, {}, callback, errCallback, [
            ['status', 'READ']
        ]);
    };

    static notificationMakeUnread = (id, callback, errCallback) => {
        NetworkService.put(`${API_BASE_URL}/notification/${id}`, {}, callback, errCallback, [
            ['status', 'UNREAD']
        ]);
    };
}

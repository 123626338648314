import { Route, Routes } from 'react-router-dom';

// components
import PageNotFound from './pages/PageNotFound';
import Login from './pages/Login';
import ProtectedRoute from './components/ProtectedRoute';
import Dashboard from './pages/Dashboard';
import DashboardBeneficiario from './pages/DashboardBeneficiario';
import BandoViewBeneficiario from './pages/BandoViewBeneficiario';
import DefaultLayout from './layouts/DefaultLayout';
import Bandi from './pages/Bandi';
import BandoEdit from './pages/BandoEdit';
import BandoView from './pages/BandoView';
import BandoFormsEdit from './pages/BandoFormsEdit';
import BandoForms from './pages/BandoForms';
import BandoFormsPreview from './pages/BandoFormsPreview';
import BandoFlowEdit from './pages/BandoFlowEdit';
import Applications from './pages/Applications';
import BandoApplication from './pages/BandoApplication';
import Registration from './pages/Registration';
import BandiBeneficiario from './pages/BandiBeneficiario';
import LoginAdmin from './pages/LoginAdmin';
import Profile from './pages/Profile';
import ProfileCompany from './pages/ProfileCompany';
import Users from './pages/Users';
import AddCompany from './pages/AddCompany';
import ResetPassword from './pages/ResetPassword';
import DashboardPreInstructor from './pages/DashboardPreInstructor';
import ProfileBeneficiario from './pages/ProfileBeneficiario';
import Domande from './pages/Domande';
import DomandePreInstructor from './pages/DomandePreInstructor';
import DomandaEditPreInstructor from './pages/DomandaEditPreInstructor';
import SoccorsoIstruttorioPreInstructor from './pages/SoccorsoIstruttorioPreInstructor';
import SoccorsoEditPreInstructor from './pages/SoccorsoEditPreInstructor';
import SoccorsoAddPreInstructor from './pages/SoccorsoAddPreInstructor';
import DomandeBeneficiario from './pages/DomandeBeneficiario';
import SoccorsoEditBeneficiario from './pages/SoccorsoEditBeneficiario';
import BandoApplicationPreview from './pages/BandoApplicationPreview';
import BandiPreferredBeneficiario from './pages/BandiPreferredBeneficiario';
import DomandeInstructorManager from './pages/DomandeInstructorManager';
import DomandaEditInstructorManager from './pages/DomandaEditInstructorManager';
import UserActivity from './pages/UserActivity';
import DomandeArchive from './pages/DomandeArchive';

const routes = ({ role, chosenCompanyId }) => {

    return (
        <Routes>
            <Route element={<ProtectedRoute/>}>
                <Route path="/" element={<DefaultLayout>
                    {'ROLE_SUPER_ADMIN' === role ? <Dashboard/> : null}
                    {'ROLE_BENEFICIARY' === role ? <DashboardBeneficiario/> : null}
                    {'ROLE_PRE_INSTRUCTOR' === role ? <DashboardPreInstructor/> : null}
                    {'ROLE_INSTRUCTOR_MANAGER' === role ? <DashboardPreInstructor/> : null}
                </DefaultLayout>}/>
                <Route path="/bandi" element={<DefaultLayout>
                    {'ROLE_SUPER_ADMIN' === role ? <Bandi/> : null}
                    {'ROLE_BENEFICIARY' === role ? <BandiBeneficiario/> : null}
                    {'ROLE_PRE_INSTRUCTOR' === role ? <PageNotFound/> : null}
                    {'ROLE_INSTRUCTOR_MANAGER' === role ? <PageNotFound/> : null}
                </DefaultLayout>}/>
                <Route path="/bandi/:id" element={<DefaultLayout>
                    {'ROLE_SUPER_ADMIN' === role ? <BandoEdit/> : null}
                    {'ROLE_BENEFICIARY' === role ? <BandoViewBeneficiario/> : null}
                    {'ROLE_PRE_INSTRUCTOR' === role ? <PageNotFound/> : null}
                    {'ROLE_INSTRUCTOR_MANAGER' === role ? <PageNotFound/> : null}
                </DefaultLayout>}/>
                <Route path="/bandi/:id/preview" element={<DefaultLayout>
                    {'ROLE_SUPER_ADMIN' === role ? <BandoView/> : null}
                    {'ROLE_BENEFICIARY' === role ? <PageNotFound/> : null}
                    {'ROLE_PRE_INSTRUCTOR' === role ? <PageNotFound/> : null}
                    {'ROLE_INSTRUCTOR_MANAGER' === role ? <PageNotFound/> : null}
                </DefaultLayout>}/>
                <Route path="/bandi/:id/preview-evaluation" element={<DefaultLayout>
                    {'ROLE_SUPER_ADMIN' === role ? <BandoView/> : null}
                    {'ROLE_BENEFICIARY' === role ? <PageNotFound/> : null}
                    {'ROLE_PRE_INSTRUCTOR' === role ? <PageNotFound/> : null}
                    {'ROLE_INSTRUCTOR_MANAGER' === role ? <PageNotFound/> : null}
                </DefaultLayout>}/>
                <Route path="/bandi/:id/forms" element={<DefaultLayout>
                    {'ROLE_SUPER_ADMIN' === role ? <BandoForms/> : null}
                    {'ROLE_BENEFICIARY' === role ? <PageNotFound/> : null}
                    {'ROLE_PRE_INSTRUCTOR' === role ? <PageNotFound/> : null}
                    {'ROLE_INSTRUCTOR_MANAGER' === role ? <PageNotFound/> : null}
                </DefaultLayout>}/>
                <Route path="/bandi/:id/forms/:formId" element={<DefaultLayout>
                    {'ROLE_SUPER_ADMIN' === role ? <BandoFormsEdit/> : null}
                    {'ROLE_BENEFICIARY' === role ? <PageNotFound/> : null}
                    {'ROLE_PRE_INSTRUCTOR' === role ? <PageNotFound/> : null}
                    {'ROLE_INSTRUCTOR_MANAGER' === role ? <PageNotFound/> : null}
                </DefaultLayout>}/>
                <Route path="/bandi/:id/forms/:formId/preview" element={<DefaultLayout>
                    {'ROLE_SUPER_ADMIN' === role ? <BandoFormsPreview/> : null}
                    {'ROLE_BENEFICIARY' === role ? <PageNotFound/> : null}
                    {'ROLE_PRE_INSTRUCTOR' === role ? <PageNotFound/> : null}
                    {'ROLE_INSTRUCTOR_MANAGER' === role ? <PageNotFound/> : null}
                </DefaultLayout>}/>
                <Route path="/bandi/:id/flow" element={<DefaultLayout>
                    {'ROLE_SUPER_ADMIN' === role ? <BandoFlowEdit/> : null}
                    {'ROLE_BENEFICIARY' === role ? <PageNotFound/> : null}
                    {'ROLE_PRE_INSTRUCTOR' === role ? <PageNotFound/> : null}
                    {'ROLE_INSTRUCTOR_MANAGER' === role ? <PageNotFound/> : null}
                </DefaultLayout>}/>
                <Route path="/bandi-osservati" element={<DefaultLayout>
                    {'ROLE_SUPER_ADMIN' === role ? <PageNotFound/> : null}
                    {'ROLE_BENEFICIARY' === role ? <BandiPreferredBeneficiario/> : null}
                    {'ROLE_PRE_INSTRUCTOR' === role ? <PageNotFound/> : null}
                    {'ROLE_INSTRUCTOR_MANAGER' === role ? <PageNotFound/> : null}
                </DefaultLayout>}/>
                <Route path="/domande" element={<DefaultLayout>
                    {'ROLE_SUPER_ADMIN' === role ? <Domande/> : null}
                    {'ROLE_BENEFICIARY' === role ? <DomandeBeneficiario/> : null}
                    {'ROLE_PRE_INSTRUCTOR' === role ? <DomandePreInstructor/> : null}
                    {'ROLE_INSTRUCTOR_MANAGER' === role ? <DomandeInstructorManager/> : null}
                </DefaultLayout>}/>
                <Route path="/domande/:id/" element={<DefaultLayout>
                    {'ROLE_SUPER_ADMIN' === role ? <BandoApplicationPreview/> : null}
                    {'ROLE_BENEFICIARY' === role ? <SoccorsoEditBeneficiario/> : null}
                    {'ROLE_PRE_INSTRUCTOR' === role ? <DomandaEditPreInstructor/> : null}
                    {'ROLE_INSTRUCTOR_MANAGER' === role ? <DomandaEditInstructorManager/> : null}
                </DefaultLayout>}/>
                <Route path="/domande/:id/preview" element={<DefaultLayout>
                    {'ROLE_SUPER_ADMIN' === role ? <BandoApplicationPreview/> : null}
                    {'ROLE_BENEFICIARY' === role ? <BandoApplicationPreview/> : null}
                    {'ROLE_PRE_INSTRUCTOR' === role ? <BandoApplicationPreview/> : null}
                    {'ROLE_INSTRUCTOR_MANAGER' === role ? <BandoApplicationPreview/> : null}
                </DefaultLayout>}/>
                <Route path="/domande-archivio" element={<DefaultLayout>
                    {'ROLE_SUPER_ADMIN' === role ? <DomandeArchive/> : null}
                    {'ROLE_BENEFICIARY' === role ? <PageNotFound/> : null}
                    {'ROLE_PRE_INSTRUCTOR' === role ? <DomandeArchive/> : null}
                    {'ROLE_INSTRUCTOR_MANAGER' === role ? <DomandeArchive/> : null}
                </DefaultLayout>}/>
                <Route path="/domande-archivio/:id/preview" element={<DefaultLayout>
                    {'ROLE_SUPER_ADMIN' === role ? <BandoApplicationPreview/> : null}
                    {'ROLE_BENEFICIARY' === role ? <PageNotFound/> : null}
                    {'ROLE_PRE_INSTRUCTOR' === role ? <BandoApplicationPreview/> : null}
                    {'ROLE_INSTRUCTOR_MANAGER' === role ? <BandoApplicationPreview/> : null}
                </DefaultLayout>}/>
                <Route path="/domande/:id/aggiungi-soccorso" element={<DefaultLayout>
                    {'ROLE_SUPER_ADMIN' === role ? <PageNotFound/> : null}
                    {'ROLE_BENEFICIARY' === role ? <PageNotFound/> : null}
                    {'ROLE_PRE_INSTRUCTOR' === role ? <SoccorsoAddPreInstructor/> : null}
                    {'ROLE_INSTRUCTOR_MANAGER' === role ? <SoccorsoAddPreInstructor/> : null}
                </DefaultLayout>}/>
                <Route path="/domande/:id/soccorso/:amendmentId" element={<DefaultLayout>
                    {'ROLE_SUPER_ADMIN' === role ? <PageNotFound/> : null}
                    {'ROLE_BENEFICIARY' === role ? <PageNotFound/> : null}
                    {'ROLE_PRE_INSTRUCTOR' === role ? <SoccorsoEditPreInstructor/> : null}
                    {'ROLE_INSTRUCTOR_MANAGER' === role ? <SoccorsoEditPreInstructor/> : null}
                </DefaultLayout>}/>
                <Route path="/soccorso-istruttorio/" element={<DefaultLayout>
                    {'ROLE_SUPER_ADMIN' === role ? <PageNotFound/> : null}
                    {'ROLE_BENEFICIARY' === role ? <PageNotFound/> : null}
                    {'ROLE_PRE_INSTRUCTOR' === role ? <SoccorsoIstruttorioPreInstructor/> : null}
                    {'ROLE_INSTRUCTOR_MANAGER' === role ? <SoccorsoIstruttorioPreInstructor/> : null}
                </DefaultLayout>}/>
                <Route path="/imieibandi" element={<DefaultLayout>
                    {'ROLE_SUPER_ADMIN' === role ? <PageNotFound/> : null}
                    {'ROLE_BENEFICIARY' === role ? <Applications/> : null}
                    {'ROLE_PRE_INSTRUCTOR' === role ? <PageNotFound/> : null}
                    {'ROLE_INSTRUCTOR_MANAGER' === role ? <PageNotFound/> : null}
                </DefaultLayout>}/>
                <Route path="/imieibandi/:id/" element={<DefaultLayout>
                    {'ROLE_SUPER_ADMIN' === role ? <PageNotFound/> : null}
                    {'ROLE_BENEFICIARY' === role ? <BandoApplication/> : null}
                    {'ROLE_PRE_INSTRUCTOR' === role ? <PageNotFound/> : null}
                    {'ROLE_INSTRUCTOR_MANAGER' === role ? <PageNotFound/> : null}
                </DefaultLayout>}/>
                <Route path="/profilo" element={<DefaultLayout>
                    {'ROLE_SUPER_ADMIN' === role ? <Profile/> : null}
                    {'ROLE_BENEFICIARY' === role ? <ProfileBeneficiario/> : null}
                    {'ROLE_PRE_INSTRUCTOR' === role ? <Profile/> : null}
                    {'ROLE_INSTRUCTOR_MANAGER' === role ? <Profile/> : null}
                </DefaultLayout>}/>
                <Route path="/profilo-aziendale" element={<DefaultLayout>
                    {'ROLE_SUPER_ADMIN' === role ? <PageNotFound/> : null}
                    {'ROLE_BENEFICIARY' === role && chosenCompanyId > 0 ? <ProfileCompany/> : <PageNotFound/>}
                    {'ROLE_PRE_INSTRUCTOR' === role ? <PageNotFound/> : null}
                    {'ROLE_INSTRUCTOR_MANAGER' === role ? <PageNotFound/> : null}
                </DefaultLayout>}/>
                <Route path="/agguingi-azienda" element={<DefaultLayout>
                    {'ROLE_SUPER_ADMIN' === role ? <PageNotFound/> : null}
                    {'ROLE_BENEFICIARY' === role ? <AddCompany/> : null}
                    {'ROLE_PRE_INSTRUCTOR' === role ? <PageNotFound/> : null}
                    {'ROLE_INSTRUCTOR_MANAGER' === role ? <PageNotFound/> : null}
                </DefaultLayout>}/>
                <Route path="/utenti" element={<DefaultLayout>
                    {'ROLE_SUPER_ADMIN' === role ? <Users/> : null}
                    {'ROLE_BENEFICIARY' === role ? <PageNotFound/> : null}
                    {'ROLE_PRE_INSTRUCTOR' === role ? <PageNotFound/> : null}
                    {'ROLE_INSTRUCTOR_MANAGER' === role ? <PageNotFound/> : null}
                </DefaultLayout>}/>
                <Route path="/utenti/:id" element={<DefaultLayout>
                    {'ROLE_SUPER_ADMIN' === role ? <UserActivity/> : null}
                    {'ROLE_BENEFICIARY' === role ? <PageNotFound/> : null}
                    {'ROLE_PRE_INSTRUCTOR' === role ? <PageNotFound/> : null}
                    {'ROLE_INSTRUCTOR_MANAGER' === role ? <PageNotFound/> : null}
                </DefaultLayout>}/>
            </Route>
            <Route exact path="/reset-password" element={<ResetPassword/>}/>
            <Route exact path="/login" element={<Login/>}/>
            <Route exact path="/loginAdmin" element={<LoginAdmin/>}/>
            <Route exact path="/registration" element={<Registration/>}/>
            <Route path="*" element={<PageNotFound/>}/>
        </Routes>)
};

export default routes;

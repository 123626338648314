const DefaultCell = ({ getValue, row: { index }, column: { id }, table }) => {
    const initialValue = getValue();
    const disabled = table.options.meta?.disabled;

    const onBlur = (e) => {
        table.options.meta?.updateData(index, id, e.target.value);
    };

    const onFocus = (e) => {
        e.target.select();
    }

    return (
        <input
            disabled={disabled}
            value={initialValue ?? ''}
            onChange={(e) => table.options.meta?.updateData(index, id, e.target.value)}
            onBlur={onBlur}
            onFocus={onFocus}
            className="w-full px-2 py-1 border rounded"
        />
    );
};

export default DefaultCell;
import React, { useState, useEffect, useRef } from 'react';
import { __ } from '@wordpress/i18n';
import { isEmpty, pathOr } from 'ramda';
import { useNavigate, useParams } from 'react-router-dom';
import NumberFlow from '@number-flow/react';

// service
import UserService from '../../service/user-service';

// tools
import set404FromErrorResponse from '../../helpers/set404FromErrorResponse';
import getDateFromISOstring from '../../helpers/getDateFromISOstring';

// components
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { Dropdown } from 'primereact/dropdown';
import UserActionService from '../../service/user-action-service';
import UserActivityTable from './components/UserActivityTable';


const UserActivity = () => {
    const [loading, setLoading] = useState(false);
    const toast = useRef(null);
    const navigate = useNavigate();
    const { id } = useParams();
    const [user, setUser] = useState({});
    const [roles, setRoles] = useState([]);
    const [chosenRole, setChosenRole] = useState(0);
    const [actionsContext, setActionsContext] = useState([]);
    const [userActions, setUserActions] = useState({});
    const [chosenPeriod, setChosenPeriod] = useState('');
    const [chosenActivity, setChosenActivity] = useState('');


    const goBack = () => {
        navigate(`/utenti`);
    }

    const getUserCallback = (resp) => {
        if (resp.status === 'SUCCESS') {
            setUser(resp.data)
            setChosenRole(resp.data.role?.id);
        }
        setLoading(false);
    }

    const errGetUserCallback = (resp) => {
        set404FromErrorResponse(resp);
        setLoading(false);
    }

    const getRolesCallback = (resp) => {
        if (resp.status === 'SUCCESS') {
            setRoles(resp.data)
        }
        setLoading(false);
    }

    const errGetRolesCallback = (resp) => {
        set404FromErrorResponse(resp);
        setLoading(false);
    }

    const getStatValue = (key, fallback = 0) => {
        return pathOr(fallback, [key], userActions);
    }

    const handleRoleUpdate = () => {
        if (user.role?.id !== chosenRole) {
            setLoading(true);
            UserService.updateUser(user.id, { roleId: chosenRole }, updateRoleCallback, errUpdateRoleCallback)
        }
    }

    const updateRoleCallback = (resp) => {
        if (resp.status === 'SUCCESS') {
            setUser(resp.data)
        }
        setLoading(false);
    }

    const errUpdateRoleCallback = (resp) => {
        set404FromErrorResponse(resp);
        setLoading(false);
    }

    const getActionsContextCallback = (resp) => {
        if (resp.status === 'SUCCESS') {
            setActionsContext(resp.data)
        }
        setLoading(false);
    }

    const errGetActionsContextCallback = (resp) => {
        set404FromErrorResponse(resp);
        setLoading(false);
    }

    const getUserActionsCallback = (resp) => {
        if (resp.status === 'SUCCESS') {
            setUserActions(resp.data)
        }
        setLoading(false);
    }

    const errGetUserActionsCallback = (resp) => {
        set404FromErrorResponse(resp);
        setLoading(false);
    }

    const doFilterUserActivity = () => {
        let queryParams = [];

        if (!isEmpty(chosenPeriod)) {
            queryParams.push(['timeFilter', chosenPeriod])
        }
        if (!isEmpty(chosenActivity)) {
            queryParams.push(['actionContext', chosenActivity])
        }

        UserActionService.getUserActions(id, getUserActionsCallback, errGetUserActionsCallback, queryParams);
    }

    useEffect(() => {
        if (id && !isEmpty(id)) {
            setLoading(true);
            UserService.getUser(id, getUserCallback, errGetUserCallback);
            UserService.getRoles(getRolesCallback, errGetRolesCallback);
            UserActionService.getActionContext(id, getActionsContextCallback, errGetActionsContextCallback);
            UserActionService.getUserActions(id, getUserActionsCallback, errGetUserActionsCallback);
        }
    }, [id])

    return (
        <div className="appPage">
            <div className="appPage__pageHeader">
                <h1>{__('Controllo attività utenti', 'gepafin')}</h1>
            </div>

            <div className="appPage__spacer"></div>
            <Toast ref={toast}/>

            <div className="appPageSection">
                <div className="appPageSection__actions">
                    <Button
                        onClick={goBack}
                        outlined
                        label={__('Indietro', 'gepafin')} icon="pi pi-arrow-left" iconPos="left"/>
                </div>
            </div>

            <div className="appPageSection__withBorder columns">
                <p className="appPageSection__pMeta">
                    <span>{__('Nome utente', 'gepafin')}</span>
                    <span>{`${user.firstName} ${user.lastName}`}</span>
                </p>
                <p className="appPageSection__pMeta">
                    <span>{__('Email', 'gepafin')}</span>
                    <span>{user.email}</span>
                </p>
                <p className="appPageSection__pMeta">
                    <span>{__('Ruolo', 'gepafin')}</span>
                    <span>{user.role?.roleName}</span>
                </p>
                <p className="appPageSection__pMeta">
                    <span>{__('Data registrazione', 'gepafin')}</span>
                    <span>{getDateFromISOstring(user.createdDate)}</span>
                </p>
                <p className="appPageSection__pMeta">
                    <span>{__('Ultimo accesso', 'gepafin')}</span>
                    <span>{getDateFromISOstring(user.lastLogin)}</span>
                </p>
                <p className="appPageSection__pMeta">
                    <span>{__('Stato account', 'gepafin')}</span>
                    <span>{user.status}</span>
                </p>
            </div>

            {['ROLE_PRE_INSTRUCTOR', 'ROLE_INSTRUCTOR_MANAGER'].includes(user.role?.roleType)
                ? <>
                    <div className="appPage__spacer"></div>

                    <div className="appPageSection">
                        <h3>{__('Cambia ruolo', 'gepafin')}</h3>
                        <div className="row">
                            <Dropdown
                                id="form"
                                disabled={isEmpty(roles) || loading}
                                value={chosenRole}
                                onChange={(e) => setChosenRole(e.value)}
                                options={roles.filter(o => [3, 5].includes(o.id)).map(o => ({
                                    label: o.roleName,
                                    value: o.id
                                }))}
                                optionLabel="label"
                                placeholder={__('Seleziona ruolo', 'gepafin')}/>
                            <Button
                                type="button"
                                disabled={loading}
                                outlined
                                onClick={handleRoleUpdate}
                                label={__('Modifica', 'gepafin')}
                                icon="pi pi-cog" iconPos="right"/>
                        </div>
                    </div>
                </> : null}

            <div className="appPage__spacer"></div>

            {!isEmpty(userActions)
                ? <div className="appPageSection">
                    <h2>{__('Statistiche attività', 'gepafin')}</h2>
                    <div className="statsBigBadges__grid grid-small">
                        <div className="statsBigBadges__gridItem">
                            <span>{__('Login totali', 'gepafin')}</span>
                            <span>{<NumberFlow
                                value={getStatValue('numberOfLoginAttempts', 0)}
                                format={{ notation: 'compact' }}
                                locales="it-IT"/>}</span>
                        </div>
                        <div className="statsBigBadges__gridItem">
                            <span>{__('Bandi gestiti', 'gepafin')}</span>
                            <span>{<NumberFlow
                                value={getStatValue('applicationsProcessed', 0)}
                                format={{ notation: 'compact' }}
                                locales="it-IT"/>}</span>
                        </div>
                        {/*<div className="statsBigBadges__gridItem">
                            <span>{__('Domande processate', 'gepafin')}</span>
                            <span>{<NumberFlow
                                value={getStatValue('numberOfActiveCalls', 0)}
                                format={{ notation: 'compact' }}
                                locales="it-IT"/>}</span>
                        </div>*/}
                    </div>
                </div> : null}

            <div className="appPage__spacer"></div>

            {!isEmpty(userActions)
                ? <div className="appPageSection">
                    <h2>{__('Filtri attività', 'gepafin')}</h2>
                    <div className="appPageSection columns">
                        <div className="row">
                            <label>{__('Periodo', 'gepafin')}</label>
                            <Dropdown
                                value={chosenPeriod}
                                onChange={(e) => setChosenPeriod(e.value)}
                                options={[
                                    { value: 'LAST_WEEK', label: __('Ultima settimana', 'gepafin') },
                                    { value: 'LAST_QUARTER', label: __('Ultimo trimestre', 'gepafin') },
                                    { value: 'LAST_SEMESTER', label: __('Ultimo semestre', 'gepafin') },
                                    { value: 'LAST_YEAR', label: __('Ultimo anno', 'gepafin') }
                                ]}
                                optionLabel="label"/>
                        </div>
                        {!isEmpty(actionsContext)
                            ? <div className="row">
                                <label>{__('Tipo di attività', 'gepafin')}</label>
                                <Dropdown
                                    className="fullWidth"
                                    value={chosenActivity}
                                    onChange={(e) => setChosenActivity(e.value)}
                                    options={actionsContext.map(o => ({
                                        value: o.actionContext,
                                        label: o.description
                                    }))}
                                    optionLabel="label"/>
                            </div> : null}
                        <div className="row">
                            <Button
                                type="button"
                                onClick={doFilterUserActivity}
                                label={__('Applica', 'gepafin')}/>
                        </div>
                    </div>
                </div> : null}

            <div className="appPage__spacer"></div>

            {!isEmpty(userActions)
                ? <div className="appPageSection">
                    <h2>{__('Attività dettagliate', 'gepafin')}</h2>
                    <UserActivityTable data={userActions.userActions}/>
                </div> : null}
        </div>
    )
}

export default UserActivity;

import React, { useState, useEffect, useRef } from 'react';
import { __ } from '@wordpress/i18n';
import { useNavigate, useParams } from 'react-router-dom';
import { is, isEmpty, isNil } from 'ramda';
import 'quill/dist/quill.core.css';

// store
import { storeGet, storeSet, useStore } from '../../store';

// tools
import getNumberWithCurrency from '../../helpers/getNumberWithCurrency';
import getDateFromISOstring from '../../helpers/getDateFromISOstring';
import set404FromErrorResponse from '../../helpers/set404FromErrorResponse';
import renderHtmlContent from '../../helpers/renderHtmlContent';

// api
import BandoService from '../../service/bando-service';
import FaqItemService from '../../service/faq-item-service';
import ApplicationService from '../../service/application-service';

// components
import { Skeleton } from 'primereact/skeleton';
import { Accordion } from 'primereact/accordion';
import { AccordionTab } from 'primereact/accordion';
import { Button } from 'primereact/button';
import { Messages } from 'primereact/messages';
import { Message } from 'primereact/message';
import { Toast } from 'primereact/toast';
import { Editor } from 'primereact/editor';
import { Dialog } from 'primereact/dialog';
import PreferredBandoService from '../../service/preferred-bando-service';
import isDateTimeInPast from '../../helpers/isDateTimeInPast';

const REACT_APP_HUB_ID = process.env.REACT_APP_HUB_ID;

const BandoViewBeneficiario = () => {
    const isAsyncRequest = useStore().main.isAsyncRequest();
    const chosenCompanyId = useStore().main.chosenCompanyId();
    const { id } = useParams();
    const navigate = useNavigate();
    const [data, setData] = useState({});
    const [isExpired, setIsExpired] = useState(false);
    const [newQuestion, setNewQuestion] = useState('');
    const [applicationObj, setApplicationObj] = useState(true);
    const [isVisibleConfidiPopup, setIsVisibleConfidiPopup] = useState(false);
    const bandoMsgs = useRef(null);
    const toast = useRef(null);

    const displayConfidiPopup = () => {
        setIsVisibleConfidiPopup(true);
    }

    const hideConfidiPopup = () => {
        setIsVisibleConfidiPopup(false)
    }

    const scaricaModulistica = () => {
        if (data.confidi) {
            displayConfidiPopup();
        } else {
            const bandoId = getBandoId();
            BandoService.getBandoPdf(bandoId, getCallPdfCallback, errCallPdfCallback);
        }
    }

    const getCallPdfCallback = (data) => {
        const bandoId = getBandoId();
        const pdfFile = new Blob([data], { type: 'application/zip' })
        const url = window.URL.createObjectURL(pdfFile);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `modulistica-bando-${bandoId}.zip`);
        document.body.appendChild(link);
        link.click();
        link.remove();
        storeSet.main.unsetAsyncRequest();
    }

    const errCallPdfCallback = (data) => {
        if (toast.current) {
            toast.current.show({
                severity: 'error',
                summary: '',
                detail: data.message
            });
        }
        storeSet.main.unsetAsyncRequest();
    }

    const getBandoId = () => {
        const parsed = parseInt(id)
        return !isNaN(parsed) ? parsed : 0;
    }

    const submitBtnLabel = () => {
        if (REACT_APP_HUB_ID === 't7jh5wfg9QXylNaTZkPoE') {
            return __('Presenta domanda', 'gepafin');
        } else {
            if (applicationObj && applicationObj.id) {
                return __('Vai alla domanda', 'gepafin');
            } else {
                return __('Presenta domanda', 'gepafin');
            }
        }
    }

    const submitBtnIcon = () => {
        if (REACT_APP_HUB_ID === 't7jh5wfg9QXylNaTZkPoE') {
            return 'pi pi-save';
        } else {
            if (applicationObj && applicationObj.id) {
                return 'pi pi-arrow-right';
            } else {
                return 'pi pi-save';
            }
        }
    }

    const submitApplication = () => {
        if (data.confidi) {
            if (data.id === 6 && REACT_APP_HUB_ID === 'p4lk3bcx1RStqTaIVVbXs') {
                if (applicationObj && applicationObj.id) {
                    navigate(`/imieibandi/${applicationObj.id}`);
                } else {
                    const bandoId = getBandoId();
                    ApplicationService.createApplication(bandoId, {}, createApplCallback, errCreateApplCallback, [['companyId', chosenCompanyId]]);
                }
            } else {
                displayConfidiPopup();
            }
        } else {
            if (REACT_APP_HUB_ID === 't7jh5wfg9QXylNaTZkPoE') {
                const bandoId = getBandoId();
                ApplicationService.createApplication(bandoId, {}, createApplCallback, errCreateApplCallback, [['companyId', chosenCompanyId]]);
            } else {
                if (applicationObj && applicationObj.id) {
                    navigate(`/imieibandi/${applicationObj.id}`);
                } else {
                    const bandoId = getBandoId();
                    ApplicationService.createApplication(bandoId, {}, createApplCallback, errCreateApplCallback, [['companyId', chosenCompanyId]]);
                }
            }
        }
    }

    const createApplCallback = (data) => {
        storeSet.main.unsetAsyncRequest();
        if (data.status === 'SUCCESS') {
            navigate(`/imieibandi/${data.data.id}`);
        }
    }

    const errCreateApplCallback = (data) => {
        if (toast.current) {
            toast.current.show({
                severity: 'error',
                summary: '',
                detail: data.message
            });
        }
        if (bandoMsgs.current && data.message) {
            bandoMsgs.current.show([
                {
                    sticky: true, severity: 'error', summary: '',
                    detail: data.message,
                    closable: true
                }
            ]);
        }
        storeSet.main.unsetAsyncRequest();
    }

    /*const saveToFavourites = () => {

    }*/

    const submitNewQuestion = () => {
        if (newQuestion && chosenCompanyId && 0 !== chosenCompanyId) {
            if (bandoMsgs.current) {
                bandoMsgs.current.clear();
            }
            const obj = {
                'id': null,
                'lookUpDataId': null,
                'title': newQuestion,
                'value': newQuestion,
                'response': '',
                'isVisible': false
            }
            storeSet.main.setAsyncRequest();
            FaqItemService.addQuestion(id, obj, createQuestionBack, errCreateQuestionCallback, [['companyId', chosenCompanyId]])
        }
    }

    const createQuestionBack = (data) => {
        if (data.status === 'SUCCESS') {
            setNewQuestion('');
            if (toast.current) {
                toast.current.show({
                    severity: 'success',
                    summary: '',
                    detail: data.message
                });
            }
        }
        storeSet.main.unsetAsyncRequest();
    }

    const errCreateQuestionCallback = (data) => {
        if (toast.current && data.message) {
            toast.current.show({
                severity: 'error',
                summary: '',
                detail: data.message
            });
        }
        storeSet.main.unsetAsyncRequest();
    }

    const getBandoCallback = (data) => {
        if (data.status === 'SUCCESS') {
            setData(getFormattedBandiData(data.data));
        }
        storeSet.main.unsetAsyncRequest();
    }

    const errGetBandoCallback = (data) => {
        if (toast.current && data.message) {
            toast.current.show({
                severity: 'error',
                summary: '',
                detail: data.message
            });
        }
        set404FromErrorResponse(data);
        storeSet.main.unsetAsyncRequest();
    }

    const getFormattedBandiData = (data) => {
        data.dates = data.dates.map(v => is(String, v) ? new Date(v) : (v ? v : ''));
        return data;
    };

    const getApplCallback = (data) => {
        if (data.status === 'SUCCESS') {
            if (data.data.length) {
                setApplicationObj(data.data[0]);
            }
        }
        storeSet.main.unsetAsyncRequest();
    }

    const errGetApplCallback = (data) => {
        set404FromErrorResponse(data);
        storeSet.main.unsetAsyncRequest();
    }

    const renderHeader = () => {
        return (
            <span className="ql-formats">
                <button className="ql-bold" aria-label="Bold"></button>
                <button className="ql-italic" aria-label="Italic"></button>
                <button className="ql-underline" aria-label="Underline"></button>
                <button className="ql-link" aria-label="Link"></button>
                <button className="ql-list" value="ordered"></button>
                <button className="ql-header" value="2"></button>
                <button className="ql-header" value="3"></button>
                <button className="ql-blockquote"></button>
                <button className="ql-list" value="bullet"></button>
                <button className="ql-indent" value="-1"></button>
                <button className="ql-indent" value="+1"></button>
            </span>
        );
    };

    const header = renderHeader();

    const addToFavourites = () => {
        const companyId = storeGet.main.chosenCompanyId();
        const bandoId = getBandoId();
        const formdData = {
            companyId,
            callId: bandoId
        }

        if (data.preferredCallId && data.preferredCallId !== 0) {
            PreferredBandoService.deleteFromPreferred(data.preferredCallId, removeFavCallback, errToggleFavCallback);
        } else {
            PreferredBandoService.addToPreferred(formdData, addFavCallback, errToggleFavCallback);
        }
    }

    const removeFavCallback = (resp) => {
        if (resp.status === 'SUCCESS') {
            const newData = {
                ...data,
                preferredCallId: null
            }
            setData(newData);
        }
    }
    const addFavCallback = (resp) => {
        if (resp.status === 'SUCCESS') {
            const newData = {
                ...data,
                preferredCallId: resp.data.id
            }
            setData(newData);
        }
    }
    const errToggleFavCallback = (data) => {
        set404FromErrorResponse(data);
    }

    useEffect(() => {
        if (!isEmpty(chosenCompanyId) && chosenCompanyId !== 0) {
            const bandoId = getBandoId();
            storeSet.main.setAsyncRequest();
            BandoService.getBando(bandoId, getBandoCallback, errGetBandoCallback, [
                ['companyId', chosenCompanyId]
            ]);
            if (REACT_APP_HUB_ID !== 't7jh5wfg9QXylNaTZkPoE') {
                storeSet.main.setAsyncRequest();
                ApplicationService.getApplications(getApplCallback, errGetApplCallback, [
                    ['callId', bandoId],
                    ['companyId', chosenCompanyId]
                ]);
            }
        }
    }, [id, chosenCompanyId]);

    useEffect(() => {
        if (data.dates) {
            const isCallExpired = isDateTimeInPast(data.dates[1], data.endTime);
            setIsExpired(isCallExpired);
        }
    }, [data]);

    return (
        <div className="appPage">
            {!isAsyncRequest && !isEmpty(data)
                ? <div className="appPage__pageHeader">
                    <h1>{data.name}</h1>
                    <p>
                        {__('Data:', 'gepafin')}
                        <span>{getDateFromISOstring(data.createdDate)}</span>
                    </p>
                </div>
                : <>
                    <Skeleton width="20%" height="1rem" className="mb-2"></Skeleton>
                    <Skeleton width="100%" height="2rem" className="mb-8"></Skeleton>
                </>}

            <div className="appPage__spacer"></div>
            <Messages ref={bandoMsgs}/>
            <Toast ref={toast}/>

            {!isAsyncRequest && !isEmpty(data)
                ? <div className="appPage__content">
                    {!isEmpty(data.images)
                        ? <picture className="appPageSection__hero">
                            <source srcSet={data.images[0] ? data.images[0].filePath : ''}/>
                            <img src={data.images[0] ? data.images[0].filePath : ''} alt={data.name}/>
                        </picture> : null}

                    <div className="appPageSection__withBorder">
                        <h2>{__('Descrizione breve', 'gepafin')}</h2>
                        <div className="ql-editor">
                            {renderHtmlContent(data.descriptionShort)}
                        </div>
                    </div>

                    <div className="appPageSection__row">
                        <div className="appPageSection__withBorder">
                            <p className="appPageSection__pMeta">
                                <span>{__('Importo totale', 'gepafin')}</span>
                                <span>{getNumberWithCurrency(data.amount)}</span>
                            </p>
                            <p className="appPageSection__pMeta">
                                <span>{__('Importo minimo per progetto', 'gepafin')}</span>
                                <span>{getNumberWithCurrency(data.amountMin)}</span>
                            </p>
                            <p className="appPageSection__pMeta">
                                <span>{__('Importo massimo per progetto', 'gepafin')}</span>
                                <span>{getNumberWithCurrency(data.amountMax)}</span>
                            </p>
                        </div>

                        <div className="appPageSection__withBorder">
                            <p className="appPageSection__pMeta">
                                <span>{__('Data apertura', 'gepafin')}</span>
                                <span>{getDateFromISOstring(data.dates[0])} {data.startTime}</span>
                            </p>
                            <p className="appPageSection__pMeta">
                                <span>{__('Data chiusura', 'gepafin')}</span>
                                <span>{getDateFromISOstring(data.dates[1])} {data.endTime}</span>
                            </p>
                        </div>
                    </div>

                    <div className="appPageSection__withBorder">
                        <h2>{__('Descrizione dettagliata', 'gepafin')}</h2>
                        <div className="ql-editor">
                            {renderHtmlContent(data.descriptionLong)}
                        </div>
                    </div>

                    <div className="appPageSection__withBorder">
                        <h2>{__('Requisiti di Partecipazione', 'gepafin')}</h2>
                        <div className="row rowContent">
                            <ul>
                                {data.aimedTo.map((o, i) => <li key={i}>
                                    {o.value}
                                </li>)}
                            </ul>
                        </div>
                    </div>

                    <div className="appPageSection__withBorder">
                        <h2>{__('Documentazione richiesta', 'gepafin')}</h2>
                        <div className="ql-editor">
                            {renderHtmlContent(data.documentationRequested)}
                        </div>
                    </div>

                    {/*<div className="appPageSection__withBorder">
                        <h2>{__('Criteri di Valutazione', 'gepafin')}</h2>
                        <div className="row rowContent">
                            <ul>
                                {data.criteria.map((o, i) => <li key={i}>
                                    {o.value} {o.score > 0 ? sprintf(__(' (%d punti)'), o.score) : null}
                                </li>)}
                            </ul>
                        </div>
                    </div>*/}

                    <div className="appPageSection__withBorder">
                        <h2>{__('Allegati', 'gepafin')}</h2>
                        <div className="row rowContent">
                            <ul>
                                {data.docs
                                    .filter(o => o.source === 'CALL' && o.type === 'DOCUMENT')
                                    .map((o, i) => <li key={i}>
                                        <a href={o.filePath} target="_blank" rel="noreferrer">{o.name}</a>
                                    </li>)}
                            </ul>
                        </div>
                    </div>

                    <div className="appPageSection">
                        <h2>{__('FAQ', 'gepafin')}</h2>
                        <Accordion>
                            {data.faq
                                .filter(o => o.isVisible)
                                .map((o, i) => <AccordionTab key={i} header={renderHtmlContent(o.value)}>
                                    <div className="ql-editor">
                                        {renderHtmlContent(o.response)}
                                    </div>
                                </AccordionTab>)}
                        </Accordion>
                    </div>

                    {REACT_APP_HUB_ID === 't7jh5wfg9QXylNaTZkPoE'
                        ? null
                        : <div className="appPageSection">
                            <h2>{__('Non hai trovato la risposta che cercavi?', 'gepafin')}</h2>
                            <div className="appForm__field">
                                <label htmlFor="newQuestion">{__('Fai una domanda', 'gepafin')}</label>
                                <Editor
                                    id="newQuestion"
                                    value={newQuestion}
                                    placeholder={__('Digita qui la tua domanda', 'gepafin')}
                                    headerTemplate={header}
                                    onTextChange={(e) => setNewQuestion(e.htmlValue)}
                                    style={{ height: 80 * 3 }}
                                    aria-describedby="newQuestion-help"
                                />
                                <small id="newQuestion-help">
                                    {__('Riceverai una notifica quando ti risponderemo', 'gepafin')}
                                </small>
                            </div>
                            <Button
                                type="button"
                                disabled={isExpired}
                                onClick={submitNewQuestion}
                                label={__('Salva', 'gepafin')}/>
                        </div>}

                    {!chosenCompanyId || chosenCompanyId === 0
                        ? <>
                            <Message severity="error"
                                     text={__('Devi creare un\'azienda prima di partecipare nei bandi. Vai nel profilo aziendale.', 'gepafin')}/>
                        </>
                        : null}

                    {data.confidi
                        ? <Dialog header={data.name}
                                  visible={isVisibleConfidiPopup}
                                  style={{ width: '50vw' }}
                                  onHide={hideConfidiPopup}>
                            <p>
                                {__('Non risultano convenzioni attive', 'gepafin')}
                            </p>
                        </Dialog>
                        : null}

                    <div className="appPageSection">
                        <h2>{__('Download Documenti', 'gepafin')}</h2>
                        <div className="appPageSection__actions">
                            {/* <Button
                                type="button"
                                disabled={true}
                                outlined
                                onClick={scaricaBando}
                                label={__('Scarica Bando Completo', 'gepafin')}
                                icon="pi pi-download" iconPos="right"/>*/}
                            <Button
                                type="button"
                                disabled={isExpired}
                                outlined
                                onClick={scaricaModulistica}
                                label={__('Scarica Bando Completo e Modulistica', 'gepafin')}
                                icon="pi pi-download" iconPos="right"/>
                            <Button
                                type="button"
                                disabled={isExpired || isAsyncRequest || chosenCompanyId === 0}
                                onClick={submitApplication}
                                label={submitBtnLabel()}
                                icon={submitBtnIcon()} iconPos="right"/>
                            <Button
                                type="button"
                                disabled={isExpired}
                                outlined={isNil(data.preferredCallId)}
                                rounded
                                onClick={addToFavourites}
                                label={isNil(data.preferredCallId)
                                    ? __('Aggiungi a preferiti', 'gepafin')
                                    : __('Rimuovi dai preferiti', 'gepafin')}
                                icon="pi pi-heart" iconPos="left"/>
                        </div>
                    </div>

                    <div className="appPageSection__withBorder">
                        <h2>{__('Contatti per Assistenza', 'gepafin')}</h2>
                        <div className="row rowContent">
                            <p>Email: {data.email}</p>
                            {!isNil(data.phoneNumber) ?
                                <p>{__('Telefono', 'gepafin')}: +39 {data.phoneNumber}</p> : null}
                        </div>
                    </div>
                </div>
                : <>
                    <Skeleton width="20%" height="1rem" className="mb-2"></Skeleton>
                    <Skeleton width="100%" height="2rem" className="mb-8"></Skeleton>
                    <Skeleton width="20%" height="1rem" className="mb-2"></Skeleton>
                    <Skeleton width="100%" height="4rem" className="mb-8"></Skeleton>
                    <Skeleton width="20%" height="1rem" className="mb-2"></Skeleton>
                    <Skeleton width="100%" height="2rem" className="mb-8"></Skeleton>
                    <Skeleton width="20%" height="1rem" className="mb-2"></Skeleton>
                    <Skeleton width="100%" height="4rem"></Skeleton>
                </>}
        </div>
    )

}

export default BandoViewBeneficiario;

const NumericFormulaCell = ({ getValue, row: { index }, column: { id }, table }) => {
    const initialValue = getValue();
    const disabled = table.options.meta?.disabled;

    const onBlur = (e) => {
        const numValue = e.target.value === 0 ? null : Number(e.target.value);
        table.options.meta?.updateData(index, id, numValue);
    };

    const onFocus = (e) => {
        e.target.select();
    }

    const onChange = (e) => {
        if (e.target.value === 0 || !isNaN(e.target.value)) {
            table.options.meta?.updateData(index, id, e.target.value);
        }
    };

    return (
        <input
            type="number"
            disabled={disabled}
            value={initialValue ?? 0}
            onChange={onChange}
            onFocus={onFocus}
            onBlur={onBlur}
            step="any"
            className="w-full px-2 py-1 border rounded"
        />
    );
};

export default NumericFormulaCell;
import React, { useState, useEffect } from 'react';
import { __ } from '@wordpress/i18n';
import { uniq, isEmpty, isNil } from 'ramda';
import { Link } from 'react-router-dom';

// store
import { storeGet, useStore } from '../../../../store';

// api
import BandoService from '../../../../service/bando-service';
import PreferredBandoService from '../../../../service/preferred-bando-service';

// tools
import set404FromErrorResponse from '../../../../helpers/set404FromErrorResponse';

// components
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import ProperBandoLabel from '../../../../components/ProperBandoLabel';

import translationStrings from '../../../../translationStringsForComponents';


const LatestBandiTable = () => {
    const chosenCompanyId = useStore().main.chosenCompanyId();
    const [items, setItems] = useState(null);
    const [filters, setFilters] = useState(null);
    const [loading, setLoading] = useState(false);
    const [, setStatuses] = useState([]);

    useEffect(() => {
        setLoading(true);
        BandoService.getBandi(getCallback, errGetCallbacks, [
            ['companyId', chosenCompanyId]
        ]);
    }, [chosenCompanyId]);

    const getCallback = (data) => {
        if (data.status === 'SUCCESS') {
            const newItems = data.data.filter(o => o.status === 'PUBLISH');
            setItems(getFormattedData(newItems));
            setStatuses(uniq(data.data.map(o => o.status)))
            initFilters();
        }
        setLoading(false);
    }

    const errGetCallbacks = (data) => {
        set404FromErrorResponse(data);
        setLoading(false);
    }

    const addToFavourites = (id, preferredId) => {
        const companyId = storeGet.main.chosenCompanyId()
        const data = {
            companyId,
            callId: id
        }
        setLoading(true);
        if (preferredId && preferredId !== 0) {
            PreferredBandoService.deleteFromPreferred(preferredId, (data) => removeFavCallback(data, id), errToggleFavCallback);
        } else {
            PreferredBandoService.addToPreferred(data, addFavCallback, errToggleFavCallback);
        }
    }

    const removeFavCallback = (data, id) => {
        if (data.status === 'SUCCESS') {
            const newItems = items.map((o) => {
                if (o.id === id) {
                    return {
                        ...o,
                        preferredCallId: null
                    }
                } else {
                    return o;
                }
            });
            setItems(newItems)
        }
        setLoading(false);
    }
    const addFavCallback = (data) => {
        if (data.status === 'SUCCESS') {
            const newItems = items.map((o) => {
                if (o.id === data.data.callId) {
                    return {
                        ...o,
                        preferredCallId: data.data.id
                    }
                } else {
                    return o;
                }
            });
            setItems(newItems)
        }
        setLoading(false);
    }

    const errToggleFavCallback = (data) => {
        set404FromErrorResponse(data);
        setLoading(false);
    }

    const getFormattedData = (data) => {
        return [...(data || [])].map((d) => {
            d.start_date = new Date(d.dates[0]);
            d.end_date = new Date(d.dates[1]);

            return d;
        });
    };

    const formatDate = (value) => {
        return value.toLocaleDateString('it-IT', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        });
    };

    const clearFilter = () => {
        initFilters();
    };

    const initFilters = () => {
        setFilters({
            global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            name: {
                operator: FilterOperator.AND,
                constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]
            },
            start_date: {
                operator: FilterOperator.AND,
                constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }]
            },
            end_date: {
                operator: FilterOperator.AND,
                constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }]
            },
            status: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] }
        });
    };

    const renderHeader = () => {
        return (
            <div className="appTableHeader">
                <Button type="button" icon="pi pi-filter-slash" label={__('Pulisci', 'gepafin')} outlined
                        onClick={clearFilter}/>
            </div>
        );
    };

    const dateStartBodyTemplate = (rowData) => {
        return formatDate(rowData.start_date);
    };

    const dateEndBodyTemplate = (rowData) => {
        return formatDate(rowData.end_date);
    };

    const dateFilterTemplate = (options) => {
        return <Calendar value={options.value} onChange={(e) => options.filterCallback(e.value, options.index)}
                         dateFormat="mm/dd/yy" placeholder="mm/dd/yyyy" mask="99/99/9999"/>;
    };

    const statusBodyTemplate = (rowData) => {
        return <ProperBandoLabel status={rowData.status}/>;
    };

    const actionsBodyTemplate = (rowData) => {
        return <div className="appPageSection__tableActions">
            <button type="button"
                    className="appPageSection__addToFavourites"
                    data-active={!isNil(rowData.preferredCallId)}
                    onClick={() => addToFavourites(rowData.id, rowData.preferredCallId)}>
                <i className="pi pi-heart" style={{ fontSize: '1rem' }}></i>
            </button>
            <Link to={`/bandi/${rowData.id}`}>
                <Button severity="info" label={__('Partecipa', 'gepafin')} icon="pi pi-arrow-right" size="small"
                        iconPos="right"/>
            </Link></div>
    }

    const header = renderHeader();

    return (
        <div className="appPageSection__table">
            <DataTable value={items} paginator showGridlines rows={5} loading={loading} dataKey="id"
                       filters={filters} stripedRows removableSort
                       header={header}
                       emptyMessage={translationStrings.emptyMessage}
                       onFilter={(e) => setFilters(e.filters)}>
                <Column field="name" header={__('Nome Bando', 'gepafin')}
                        filter sortable
                        filterPlaceholder={__('Cerca il nome', 'gepafin')}
                        style={{ minWidth: '8rem' }}/>
                <Column header={__('Data Pubblicazione', 'gepafin')} filterField="start_date" dataType="date"
                        style={{ minWidth: '8rem' }}
                        body={dateStartBodyTemplate} filter filterElement={dateFilterTemplate}/>
                <Column header={__('Data Scadenza', 'gepafin')} filterField="end_date" dataType="date"
                        style={{ minWidth: '8rem' }}
                        body={dateEndBodyTemplate} filter filterElement={dateFilterTemplate}/>
                <Column field="status" header={__('Stato', 'gepafin')}
                        style={{ minWidth: '7rem' }} body={statusBodyTemplate} />
                {!isEmpty(chosenCompanyId) && chosenCompanyId !== 0
                    ? <Column header={__('Azioni', 'gepafin')}
                        body={actionsBodyTemplate}/> : null}
            </DataTable>
        </div>
    )
}

export default LatestBandiTable;
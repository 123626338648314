import React, { useEffect, useState } from 'react';
import { __ } from '@wordpress/i18n';

// components
import PreInstructorDomandeTable from '../DashboardPreInstructor/components/PreInstructorDomandeTable';
import { pathOr } from 'ramda';
import DashboardService from '../../service/dashboard-service';
import NumberFlow from '@number-flow/react';

const DomandePreInstructor = () => {
    const [mainStats, setMainStats] = useState({});

    const getStats = (data) => {
        if (data.status === 'SUCCESS') {
            setMainStats(data.data);
        }
    }

    const errGetStats = () => {}

    const getStatValue = (key, fallback = '') => {
        return pathOr(fallback, [key], mainStats);
    }

    useEffect(() => {
        DashboardService.getEvaluationsStats(getStats, errGetStats);
    }, []);

    return(
        <div className="appPage">
            <div className="appPage__pageHeader">
                <h1>{__('Domande da valutare', 'gepafin')}</h1>
            </div>

            <div className="appPage__spacer"></div>

            <div className="appPageSection">
                <PreInstructorDomandeTable/>
            </div>

            <div className="appPage__spacer"></div>

            <div className="appPageSection statsBigBadges">
                <h2>{__('Riepilogo', 'gepafin')}</h2>
                <div className="statsBigBadges__grid applStats">
                    <div className="statsBigBadges__gridItem">
                        <span>{__('Totale domande', 'gepafin')}</span>
                        <span><NumberFlow
                            value={getStatValue('numberOfAssignedApplication', 0)}
                            format={{ notation: 'compact' }}
                            locales="it-IT"/></span>
                    </div>
                    <div className="statsBigBadges__gridItem">
                        <span>{__('In soccorso', 'gepafin')}</span>
                        <span><NumberFlow
                            value={getStatValue('numberOfApplicationInAmendmentState', 0)}
                            format={{ notation: 'compact' }}
                            locales="it-IT"/></span>
                    </div>
                    <div className="statsBigBadges__gridItem">
                        <span>{__('In valutazione', 'gepafin')}</span>
                        <span><NumberFlow
                            value={getStatValue('numberOfApplicationInOpenState', 0)}
                            format={{ notation: 'compact' }}
                            locales="it-IT"/></span>
                    </div>
                    <div className="statsBigBadges__gridItem">
                        <span>{__('Completate', 'gepafin')}</span>
                        <span><NumberFlow
                            value={getStatValue('numberOfApplicationInCloseState', 0)}
                            format={{ notation: 'compact' }}
                            locales="it-IT"/></span>
                    </div>
                    <div className="statsBigBadges__gridItem">
                        <span>{__('Tempo medio di valutazione', 'gepafin')}</span>
                        <span><NumberFlow
                            value={getStatValue('averageEvaluationDays', 0)}
                            format={{ notation: 'compact' }}
                            suffix={` ${__('giorni', 'gepafin')}`}
                            locales="it-IT"/></span>
                    </div>
                    <div className="statsBigBadges__gridItem">
                        <span>{__('Domande in scadenza (48h)', 'gepafin')}</span>
                        <span><NumberFlow
                            value={getStatValue('numberOfApplicationExpiringIn48Hours', 0)}
                            format={{ notation: 'compact' }}
                            locales="en-US"/></span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DomandePreInstructor;
import React, { useEffect, useRef, useState } from 'react';
import { __ } from '@wordpress/i18n';
import { isEmpty, pathOr } from 'ramda';

// api
import UserService from '../../service/user-service';
import AssignedApplicationService from '../../service/assigned-application-service';

// store
import { storeSet } from '../../store';

// tools
import set404FromErrorResponse from '../../helpers/set404FromErrorResponse';
import uniqid from '../../helpers/uniqid';

// components
import AllDomandeTable from './components/AllDomandeTable';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { classNames } from 'primereact/utils';
import { Dropdown } from 'primereact/dropdown';
import DraftApplicationsTable from '../Dashboard/components/DraftApplicationsTable';
import NumberFlow from '@number-flow/react';
import DashboardService from '../../service/dashboard-service';

const Domande = () => {
    const [loading, setLoading] = useState(false);
    const [isVisibleEditDialog, setIsVisibleEditDialog] = useState(false);
    const [roleIds, setRoleIds] = useState([]);
    const [users, setUsers] = useState([]);
    const [chosenUser, setChosenUser] = useState(0);
    const [chosenApplication, setChosenApplication] = useState(0);
    const [updaterString, setUpdaterString] = useState('');
    const [mainStats, setMainStats] = useState({});
    const toast = useRef(null);

    const getRolesCallback = (data) => {
        if (data.status === 'SUCCESS') {
            const roles = data.data
                .filter(o => ['ROLE_PRE_INSTRUCTOR', 'ROLE_INSTRUCTOR_MANAGER'].includes(o.roleType));
            setRoleIds(roles.map(o => o.id));
        }
        setLoading(false);
    }

    const errGetRolesCallback = (data) => {
        set404FromErrorResponse(data);
        setLoading(false);
    }

    const getUsersCallback = (data) => {
        if (data.status === 'SUCCESS') {
            const users = data.data
                .map(o => ({
                    name: `${o.firstName} ${o.lastName} (${o.email})`,
                    value: o.id
                }));
            setUsers(users);
        }
        setLoading(false);
    }

    const errGetUsersCallback = (data) => {
        set404FromErrorResponse(data);
        setLoading(false);
    }

    const headerEditDialog = () => {
        return <span>{__('Assegni la domanda', 'gepafin')}</span>
    }

    const hideEditDialog = () => {
        setIsVisibleEditDialog(false);
        setChosenUser(0);
        setChosenApplication(0);
    }

    const footerEditDialog = () => {
        return <div>
            <Button type="button" label={__('Anulla', 'gepafin')} onClick={hideEditDialog} outlined/>
            <Button
                type="button"
                disabled={loading}
                label={__('Invia', 'gepafin')} onClick={saveEditDialog}/>
        </div>
    }

    const openAssignDialog = (applId) => {
        setChosenApplication(applId)
        setIsVisibleEditDialog(true);
    }

    const saveEditDialog = () => {
        if (chosenUser !== 0 && chosenApplication !== 0) {
            storeSet.main.setAsyncRequest();
            AssignedApplicationService.assignApplication(chosenApplication, assignApplCallback, errAssignApplCallback, [
                ['userId', chosenUser]
            ]);
            hideEditDialog();
        }
    }

    const assignApplCallback = (data) => {
        if (data.status === 'SUCCESS') {
            if (toast.current) {
                toast.current.show({
                    severity: 'success',
                    summary: '',
                    detail: data.message
                });
            }
            setUpdaterString(uniqid());
        }
        storeSet.main.unsetAsyncRequest();
    }

    const errAssignApplCallback = (data) => {
        if (toast.current && data.message) {
            toast.current.show({
                severity: 'error',
                summary: '',
                detail: data.message
            });
        }
        set404FromErrorResponse(data);
        storeSet.main.unsetAsyncRequest();
    }

    const getStatValue = (key, fallback = '') => {
        return pathOr(fallback, [key], mainStats);
    }

    const getStats = (data) => {
        if (data.status === 'SUCCESS') {
            setMainStats(data.data);
        }
    }

    const errGetStats = () => {}

    useEffect(() => {
        if (roleIds.length > 0) {
            setLoading(true);
            UserService.getUsers(getUsersCallback, errGetUsersCallback, [['roleIds', roleIds]])
        }
    }, [roleIds]);

    useEffect(() => {
        if (isVisibleEditDialog) {
            setLoading(true);
            UserService.getRoles(getRolesCallback, errGetRolesCallback)
        }
    }, [isVisibleEditDialog]);

    useEffect(() => {
        DashboardService.getApplicationsStats(getStats, errGetStats);
    }, []);

    return (
        <div className="appPage">
            <div className="appPage__pageHeader">
                <h1>{__('Gestione domande', 'gepafin')}</h1>
            </div>

            <div className="appPage__spacer"></div>

            <div className="appPageSection">
                <h2>{__('Domande pubblicate', 'gepafin')}</h2>
                <AllDomandeTable openDialogFn={openAssignDialog} updaterString={updaterString}/>
            </div>

            <div className="appPage__spacer"></div>

            <div className="appPageSection statsBigBadges">
                <h2>{__('Riepilogo', 'gepafin')}</h2>
                <div className="statsBigBadges__grid applStats">
                    <div className="statsBigBadges__gridItem">
                        <span>{__('Totale domande', 'gepafin')}</span>
                        <span><NumberFlow
                            value={getStatValue('numberOfApplication', 0)}
                            format={{ notation: 'compact' }}
                            locales="it-IT"/></span>
                    </div>
                    <div className="statsBigBadges__gridItem">
                        <span>{__('Assegnate', 'gepafin')}</span>
                        <span><NumberFlow
                            value={getStatValue('numberOfAssignedApplication', 0)}
                            format={{ notation: 'compact' }}
                            locales="it-IT"/></span>
                    </div>
                    <div className="statsBigBadges__gridItem">
                        <span>{__('Completate', 'gepafin')}</span>
                        <span><NumberFlow
                            value={getStatValue('numberOfAcceptedApplication', 0)}
                            format={{ notation: 'compact' }}
                            locales="it-IT"/></span>
                    </div>
                    <div className="statsBigBadges__gridItem">
                        <span>{__('Tempo medio di valutazione', 'gepafin')}</span>
                        <span><NumberFlow
                            value={getStatValue('evaluationAverageTime', 0)}
                            format={{ notation: 'compact' }}
                            suffix={` ${__('giorni', 'gepafin')}`}
                            locales="it-IT"/></span>
                    </div>
                    <div className="statsBigBadges__gridItem">
                        <span>{__('In soccorso istruttorio', 'gepafin')}</span>
                        <span><NumberFlow
                            value={getStatValue('numberOfApplicationInAmendmentState', 0)}
                            format={{ notation: 'compact' }}
                            locales="it-IT"/></span>
                    </div>
                    <div className="statsBigBadges__gridItem">
                        <span>{__('Domande in scadenza (48h)', 'gepafin')}</span>
                        <span><NumberFlow
                            value={getStatValue('numberOfDueApplication', 0)}
                            format={{ notation: 'compact' }}
                            locales="en-US"/></span>
                    </div>
                </div>
            </div>

            <div className="appPage__spacer"></div>

            <div className="appPageSection">
                <h2>{__('Domande in bozza', 'gepafin')}</h2>
                <DraftApplicationsTable/>
            </div>

            <Dialog
                visible={isVisibleEditDialog}
                modal
                header={headerEditDialog}
                footer={footerEditDialog}
                style={{ maxWidth: '600px', width: '100%' }}
                onHide={hideEditDialog}>
                <div className="appForm__field">
                    <label
                        className={classNames({ 'p-error': isEmpty(chosenUser) || chosenUser === 0 || chosenApplication === 0 })}>
                        {__('Istruttore', 'gepafin')}*
                    </label>
                    <Dropdown
                        value={chosenUser}
                        invalid={isEmpty(chosenUser) || chosenUser === 0 || chosenApplication === 0}
                        onChange={(e) => setChosenUser(e.value)}
                        options={users}
                        optionLabel="name"
                        optionValue="value"/>
                </div>
            </Dialog>
        </div>
    )
}

export default Domande;
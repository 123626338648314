import React from 'react';
import { __ } from '@wordpress/i18n';
import { intersection, is } from 'ramda';

// store
import { useStore } from '../../../../store';

// components
import { NavLink } from 'react-router-dom';
import HelpIcon from '../../../../icons/HelpIcon';

const AppSidebar = () => {
    const permissions = useStore().main.getPermissions();

    const items = [
        {
            label: __('Riepilogo', 'gepafin'),
            icon: 'pi pi-objects-column',
            href: '/',
            id: 1,
            enable: true
        },
        {
            label: __('Gestione bandi', 'gepafin'),
            icon: 'pi pi-file',
            href: '/bandi',
            id: 2,
            enable: intersection(permissions, ['MANAGE_TENDERS']).length
        },
        {
            label: __('Domande in lavorazione', 'gepafin'),
            icon: 'pi pi-file',
            href: '/imieibandi',
            id: 3,
            enable: intersection(permissions, ['APPLY_CALLS']).length
        },
        {
            label: __('Bandi disponibili', 'gepafin'),
            icon: 'pi pi-bookmark',
            href: '/bandi',
            id: 4,
            enable: intersection(permissions, ['VIEW_CALLS']).length
        },
        {
            label: __('Bandi osservati', 'gepafin'),
            icon: 'pi pi-star',
            href: '/bandi-osservati',
            id: 5,
            enable: intersection(permissions, ['VIEW_CALLS']).length
        },
        {
            label: __('Gestione domande', 'gepafin'),
            icon: 'pi pi-file',
            href: '/domande',
            id: 6,
            enable: intersection(permissions, ['VIEW_USERS', 'MANAGE_USERS']).length
        },
        {
            label: __('Domande da valutare', 'gepafin'),
            icon: 'pi pi-calendar-clock',
            href: '/domande',
            id: 7,
            enable: intersection(permissions, ['EVALUATE_APPLICATIONS']).length
        },
        {
            label: __('Archivio domande', 'gepafin'),
            icon: 'pi pi-briefcase',
            href: '/domande',
            id: 8,
            enable: intersection(permissions, ['APPLY_CALLS']).length
        },
        {
            label: __('Archivio domande', 'gepafin'),
            icon: 'pi pi-briefcase',
            href: '/domande-archivio',
            id: 9,
            enable: intersection(permissions, ['VIEW_USERS', 'MANAGE_USERS']).length
        },
        {
            label: __('Archivio domande', 'gepafin'),
            icon: 'pi pi-briefcase',
            href: '/domande-archivio',
            id: 10,
            enable: intersection(permissions, ['EVALUATE_APPLICATIONS']).length
        },
        {
            label: __('Soccorso istruttorio', 'gepafin'),
            icon: <HelpIcon/>,
            href: '/soccorso-istruttorio',
            id: 11,
            enable: intersection(permissions, ['EVALUATE_APPLICATIONS']).length
        },
        {
            label: __('Gestione utenti', 'gepafin'),
            icon: 'pi pi-users',
            href: '/utenti',
            id: 12,
            enable: intersection(permissions, ['VIEW_USERS', 'MANAGE_USERS']).length
        },
        {
            label: __('Configurazione', 'gepafin'),
            icon: 'pi pi-cog',
            //href: '/configurazione',
            id: 13,
            enable: false
        },
        {
            label: __('Report e Analisi', 'gepafin'),
            icon: 'pi pi-chart-bar',
            //href: '/stats',
            id: 14,
            enable: false
        },
        {
            label: __('Log di Sistema', 'gepafin'),
            icon: 'pi pi-receipt',
            clickFn: () => {},
            id: 15,
            enable: false
        }
    ]

    return <aside>
        <ul>
            {items
                .filter(o => o.enable)
                .map(o => <li key={o.id}>
                {o.href
                    ? <NavLink to={o.href}>
                        {is(String, o.icon)
                            ? <i className={o.icon}></i>
                            : o.icon}
                    <span>{o.label}</span>
                </NavLink>
                    : <button onClick={() => {}}>
                        {is(String, o.icon)
                            ? <i className={o.icon}></i>
                            : o.icon}
                        <span>{o.label}</span>
                    </button>}
            </li>)}
        </ul>
    </aside>
}

export default AppSidebar;
import React, { useMemo, useState, useCallback } from 'react';
import { useForm, useFieldArray } from 'react-hook-form';
import { isEmpty, head } from 'ramda';
import { __ } from '@wordpress/i18n';
import { klona } from 'klona';

// tools
import uniqid from '../../../../helpers/uniqid';

// components
import FormField from '../../../../components/FormField';
import { Button } from 'primereact/button';

const RepeaterFields = ({
                            sourceId,
                            sourceName,
                            updateFn = () => {
                            },
                            updateCallbackFn = () => {
                            },
                            defaultValue = [],
                            shouldDisable = false
                        }) => {
    const [chosen, setChosen] = useState('');
    const {
        control,
        handleSubmit,
        formState: { errors },
        setValue,
        register,
        trigger,
        getValues,
        watch
    } = useForm({
        defaultValues: useMemo(() => {
            return {
                items: defaultValue || []
            };
        }, [defaultValue]), mode: 'onChange'
    });
    const { fields, append, remove } = useFieldArray({
        control,
        name: 'items'
    });

    const watchFields = watch('items');

    const onSubmit = () => {
    }

    const doUpdateAfterFileUploaded = () => {
        const formData = getValues();
        updateFn(formData.items);
        updateCallbackFn(formData.items);
    }

    const addNew = () => {
        const uid = uniqid('f');
        const newItem = {
            fieldId: uid,
            nameValue: '',
            fileValue: []
        }
        append(newItem);
        setChosen(newItem.fieldId);
        trigger();
    };

    const setNewChosen = useCallback((id) => {
        const chosenObj = head(fields.filter(o => id === o.fieldId));
        if (chosenObj) {
            setChosen(chosen === id ? '' : id);
        }
    }, [fields, chosen]);

    const removeItem = useCallback((index) => {
        const chosenObj = klona(fields[index]);
        remove(index);
        if (chosen === chosenObj.fieldId) {
            setChosen('');
        }
        const formData = getValues();
        updateFn(formData.items);
        updateCallbackFn(formData.items);
    }, [fields, chosen]);

    return (
        <div className="fieldsRepeater">
            <form className="appForm" onSubmit={handleSubmit(onSubmit)}>
                {watchFields
                    ? watchFields.map((o, index) => <div key={o.fieldId}
                                                         className="fieldsRepeater__panel p-panel p-component">
                            <div className="fieldsRepeater__heading p-panel p-panel-header">
                                <span onClick={() => setNewChosen(o.fieldId)}>{o.nameValue}</span>
                                <Button icon="pi pi-times"
                                        disabled={shouldDisable}
                                        outlined
                                        severity="danger"
                                        className="actionBtn"
                                        type="button"
                                        aria-label={__('Cancella', 'gepafin')}
                                        onClick={() => removeItem(index)}/>
                            </div>
                            <div className="fieldsRepeater__fields p-panel-content" data-hide={chosen !== o.fieldId}>
                                <FormField
                                    type="textinput"
                                    disabled={shouldDisable}
                                    fieldName={`items.${index}.nameValue`}
                                    label={__('Titolo del file', 'gepafin')}
                                    control={control}
                                    errors={errors}
                                    defaultValue={o.nameValue}
                                    config={{ required: __('È obbligatorio', 'gepafin') }}
                                />
                                <FormField
                                    type="fileupload"
                                    disabled={isEmpty(o.nameValue) || shouldDisable}
                                    setDataFn={setValue}
                                    saveFormCallback={doUpdateAfterFileUploaded}
                                    fieldName={`items.${index}.fileValue`}
                                    label={__('File', 'gepafin')}
                                    control={control}
                                    register={register}
                                    errors={errors}
                                    defaultValue={o.fileValue ? o.fileValue : []}
                                    accept={[]}
                                    source={sourceName}
                                    sourceId={sourceId}
                                    multiple={false}
                                    deleteOnBackend={false}
                                    config={{ required: __('È obbligatorio', 'gepafin') }}
                                />
                            </div>
                        </div>
                    ) : null}
            </form>
            <Button
                className="fieldsRepeater__addNew"
                outlined
                type="button"
                disabled={(watchFields && watchFields.filter(o => isEmpty(o.nameValue) || isEmpty(o.fileValue)).length > 0) || shouldDisable}
                onClick={addNew}
                label={__('Aggiungi nuovo file', 'gepafin')}
            />
        </div>
    )
}

export default RepeaterFields;
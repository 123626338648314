import { NetworkService } from './network-service';

const API_BASE_URL = process.env.REACT_APP_API_EXECUTION_ADDRESS;

export default class DashboardService {

    static getAdminStats = (callback, errCallback) => {
        NetworkService.get(`${API_BASE_URL}/dashboard`, callback, errCallback);
    };

    static getApplicationsStats = (callback, errCallback) => {
        NetworkService.get(`${API_BASE_URL}/dashboard/application`, callback, errCallback);
    };

    static getAmendmentsStats = (callback, errCallback) => {
        NetworkService.get(`${API_BASE_URL}/dashboard/amendment`, callback, errCallback);
    };

    static getEvaluationsStats = (callback, errCallback) => {
        NetworkService.get(`${API_BASE_URL}/dashboard/evaluation`, callback, errCallback);
    };

    static getBeneficiaryStatsForCompany = (id, callback, errCallback) => {
        NetworkService.get(`${API_BASE_URL}/dashboard/beneficiary/company/${id}`, callback, errCallback);
    };
}

import { NetworkService } from './network-service';

const API_BASE_URL = process.env.REACT_APP_API_EXECUTION_ADDRESS;

export default class UserService {

    static getUsers = (callback, errCallback, queryParams) => {
        NetworkService.get(`${API_BASE_URL}/user`, callback, errCallback, queryParams);
    };

    static getUser = (id, callback, errCallback, queryParams) => {
        NetworkService.get(`${API_BASE_URL}/user/${id}`, callback, errCallback, queryParams);
    };

    static updateUser = (id, body, callback, errCallback) => {
        NetworkService.put(`${API_BASE_URL}/user/${id}`, body, callback, errCallback);
    };

    static createUser = (body, callback, errCallback) => {
        NetworkService.post(`${API_BASE_URL}/user`, body, callback, errCallback);
    };

    static getRoles = (callback, errCallback) => {
        NetworkService.get(`${API_BASE_URL}/role`, callback, errCallback);
    };
}

import React from 'react';
import { __ } from '@wordpress/i18n';

// components
import AllDomandeArchiveTable from './components/AllDomandeArchiveTable';

const Domande = () => {

    return (
        <div className="appPage">
            <div className="appPage__pageHeader">
                <h1>{__('Archivio domande', 'gepafin')}</h1>
            </div>

            <div className="appPage__spacer"></div>

            <div className="appPageSection">
                <h2>{__('Domande pubblicate', 'gepafin')}</h2>
                <AllDomandeArchiveTable/>
            </div>
        </div>
    )
}

export default Domande;
import React, { useState, useEffect } from 'react';
import { __ } from '@wordpress/i18n';
import { is } from 'ramda';

// translation
import translationStrings from '../../../../translationStringsForComponents';

// components
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';


const UserActivityTable = ({ data = [] }) => {
    const [items, setItems] = useState([]);
    const [filters, setFilters] = useState(null);

    useEffect(() => {
        if (data) {
            setItems(getFormattedData(data));
            initFilters();
        }
    }, [data]);

    const getFormattedData = (data) => {
        return data.map((d) => {
            d.createdDate = is(String, d.createdDate) ? new Date(d.createdDate) : (d.createdDate ? d.createdDate : '');
            d.updatedDate = is(String, d.updatedDate) ? new Date(d.updatedDate) : (d.updatedDate ? d.updatedDate : '');
            return d;
        });
    };

    const formatDate = (value) => {
        return value.toLocaleDateString('it-IT', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        });
    };

    const clearFilter = () => {
        initFilters();
    };

    const initFilters = () => {
        setFilters({
            global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            actionType: {
                operator: FilterOperator.AND,
                constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]
            },
            ipAddress: {
                operator: FilterOperator.AND,
                constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]
            },
            createdDate: {
                operator: FilterOperator.AND,
                constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }]
            }
        });
    };

    const renderHeader = () => {
        return (
            <div className="appTableHeader">
                <Button type="button" icon="pi pi-filter-slash" label={__('Pulisci', 'gepafin')} outlined
                        onClick={clearFilter}/>
            </div>
        );
    };

    const dateBodyTemplate = (rowData) => {
        return formatDate(rowData.createdDate);
    };

    const header = renderHeader();

    return (
        <div className="appPageSection__table">
            <DataTable value={items} paginator showGridlines rows={10} dataKey="id"
                       filters={filters} stripedRows removableSort
                       header={header}
                       emptyMessage={translationStrings.emptyMessage}
                       onFilter={(e) => setFilters(e.filters)}>
                <Column header={__('Timestamp', 'gepafin')}
                        filterField="createdDate" dataType="date"
                        style={{ minWidth: '8rem' }}
                        body={dateBodyTemplate}/>
                <Column field="actionType" header={__('Tipo di attività', 'gepafin')}
                        filter sortable
                        filterPlaceholder={__('Cerca', 'gepafin')}
                        style={{ minWidth: '8rem' }}/>
                <Column field="actionContext" header={__('Azione', 'gepafin')}
                        style={{ minWidth: '8rem' }}/>
                <Column field="ipAddress" header={__('IP', 'gepafin')}
                        filter sortable
                        filterPlaceholder={__('Cerca', 'gepafin')}
                        style={{ minWidth: '8rem' }}/>
            </DataTable>
        </div>
    )
}

export default UserActivityTable;

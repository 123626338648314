import React, { useState, useEffect } from 'react';
import { __ } from '@wordpress/i18n';
import { is, uniq } from 'ramda';
import { Link } from 'react-router-dom';

// api
import ApplicationService from '../../../../service/application-service';

// tools
import getBandoLabel from '../../../../helpers/getBandoLabel';
import getBandoSeverity from '../../../../helpers/getBandoSeverity';

// translation
import translationStrings from '../../../../translationStringsForComponents';

// components
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import ProperBandoLabel from '../../../../components/ProperBandoLabel';
import { Dropdown } from 'primereact/dropdown';
import { Tag } from 'primereact/tag';


const AllDomandeArchiveTable = ({ updaterString = '' }) => {
    const [items, setItems] = useState(null);
    const [filters, setFilters] = useState(null);
    const [localAsyncRequest, setLocalAsyncRequest] = useState(false);
    const [statuses, setStatuses] = useState([]);

    useEffect(() => {
        setLocalAsyncRequest(true);
        ApplicationService.getApplications(getCallback, errGetCallbacks, [
            ['statuses', ['APPROVED', 'REJECTED']]
        ]);
    }, [updaterString]);

    const getCallback = (data) => {
        if (data.status === 'SUCCESS') {
            setItems(getFormattedData(data.data));
            setStatuses(uniq(data.data.map(o => o.status)))
            initFilters();
        }
        setLocalAsyncRequest(false);
    }

    const errGetCallbacks = (data) => {
        setLocalAsyncRequest(false);
    }

    const getFormattedData = (data) => {
        return data.map((d) => {
            d.callEndDate = is(String, d.callEndDate) ? new Date(d.callEndDate) : (d.callEndDate ? d.callEndDate : '');
            d.modifiedDate = is(String, d.modifiedDate) ? new Date(d.modifiedDate) : (d.modifiedDate ? d.modifiedDate : '');
            d.submissionDate = is(String, d.submissionDate) ? new Date(d.submissionDate) : (d.submissionDate ? d.submissionDate : '');
            return d;
        });
    };

    const formatDate = (value) => {
        return value.toLocaleDateString('it-IT', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        });
    };

    const clearFilter = () => {
        initFilters();
    };

    const initFilters = () => {
        setFilters({
            global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            callTitle: {
                operator: FilterOperator.AND,
                constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]
            },
            companyName: {
                operator: FilterOperator.AND,
                constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]
            },
            submissionDate: {
                operator: FilterOperator.AND,
                constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }]
            },
            callEndDate: {
                operator: FilterOperator.AND,
                constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }]
            },
            status: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
        });
    };

    const renderHeader = () => {
        return (
            <div className="appTableHeader">
                <Button type="button" icon="pi pi-filter-slash" label={__('Pulisci', 'gepafin')} outlined
                        onClick={clearFilter}/>
            </div>
        );
    };

    const dateAppliedBodyTemplate = (rowData) => {
        return formatDate(rowData.submissionDate);
    };

    const statusFilterTemplate = (options) => {
        return <Dropdown value={options.value} options={statuses}
                         onChange={(e) => options.filterCallback(e.value, options.index)}
                         itemTemplate={statusItemTemplate} placeholder={translationStrings.selectOneLabel} className="p-column-filter"
                         showClear/>;
    };

    const dateFilterTemplate = (options) => {
        return <Calendar value={options.value} onChange={(e) => options.filterCallback(e.value, options.index)}
                         dateFormat="mm/dd/yy" placeholder="mm/dd/yyyy" mask="99/99/9999"/>;
    };

    const statusBodyTemplate = (rowData) => {
        return <ProperBandoLabel status={rowData.status}/>;
    };

    const statusItemTemplate = (option) => {
        return <Tag value={getBandoLabel(option)} severity={getBandoSeverity(option)}/>;
    };

    const actionsBodyTemplate = (rowData) => {
        return <div className="appPageSection__tableActions lessGap">
            <Link to={`/domande-archivio/${rowData.id}/preview`}>
                <Button severity="info" label={__('Anteprima', 'gepafin')} icon="pi pi-eye" size="small"
                        iconPos="right"/>
            </Link>
        </div>
    }

    const header = renderHeader();

    return (
        <div className="appPageSection__table">
            <DataTable value={items} paginator showGridlines rows={5} loading={localAsyncRequest} dataKey="id"
                       filters={filters} stripedRows removableSort
                       header={header}
                       emptyMessage={translationStrings.emptyMessage}
                       onFilter={(e) => setFilters(e.filters)}>
                <Column field="id" header={__('ID domanda', 'gepafin')}
                        sortable filterPlaceholder={__('Cerca', 'gepafin')}
                        style={{ minWidth: '6rem' }}/>
                <Column field="protocolNumber" header={__('Protocollo', 'gepafin')}
                        sortable filterPlaceholder={__('Cerca', 'gepafin')}
                        style={{ minWidth: '6rem' }}/>
                <Column field="callTitle" header={__('Bando', 'gepafin')}
                        filter sortable
                        filterPlaceholder={__('Cerca', 'gepafin')}
                        style={{ minWidth: '10rem' }}/>
                <Column field="companyName" header={__('Azienda Beneficiaria', 'gepafin')}
                        filter sortable
                        filterPlaceholder={__('Cerca il nome', 'gepafin')}
                        style={{ minWidth: '8rem' }}/>
                <Column header={__('Data Ricezione', 'gepafin')}
                        filterField="submissionDate" dataType="date"
                        style={{ minWidth: '8rem' }}
                        body={dateAppliedBodyTemplate} filter filterElement={dateFilterTemplate}/>
                <Column field="assignedUserName" header={__('Assegnato', 'gepafin')}
                        filter sortable
                        filterPlaceholder={__('Cerca il nome', 'gepafin')}
                        style={{ minWidth: '8rem' }}/>
                <Column field="status" header={__('Stato', 'gepafin')}
                        style={{ minWidth: '8rem' }} body={statusBodyTemplate}
                        filter
                        filterElement={statusFilterTemplate}/>
                <Column header={__('Azioni', 'gepafin')}
                        body={actionsBodyTemplate}/>
            </DataTable>
        </div>
    )
}

export default AllDomandeArchiveTable;

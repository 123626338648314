import { NetworkService } from './network-service';

const API_BASE_URL = process.env.REACT_APP_API_EXECUTION_ADDRESS;

export default class UserActionService {

    static getActionContext = (id, callback, errCallback, queryParams) => {
        NetworkService.get(`${API_BASE_URL}/userAction/user/${id}/action-context`, callback, errCallback, queryParams);
    };

    static getUserActions = (id, callback, errCallback, queryParams) => {
        NetworkService.get(`${API_BASE_URL}/userAction/user/${id}`, callback, errCallback, queryParams);
    };
}

import React, { useState, useEffect } from 'react';
import { __ } from '@wordpress/i18n';
import { is, isEmpty, uniq } from 'ramda';
import { Link } from 'react-router-dom';

// store
import { useStore } from '../../../../store';

// api
import ApplicationService from '../../../../service/application-service';

// tools
import getBandoLabel from '../../../../helpers/getBandoLabel';
import getBandoSeverity from '../../../../helpers/getBandoSeverity';

// components
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import ProperBandoLabel from '../../../../components/ProperBandoLabel';
import { Dropdown } from 'primereact/dropdown';
import { Tag } from 'primereact/tag';
import translationStrings from '../../../../translationStringsForComponents';


const BeneficiarioDomandeTable = () => {
    const chosenCompanyId = useStore().main.chosenCompanyId();
    const [items, setItems] = useState(null);
    const [filters, setFilters] = useState(null);
    const [localAsyncRequest, setLocalAsyncRequest] = useState(false);
    const [statuses, setStatuses] = useState([]);

    useEffect(() => {
        if (!isEmpty(chosenCompanyId) && chosenCompanyId !== 0 && !localAsyncRequest) {
            setLocalAsyncRequest(true);
            ApplicationService.getApplications(getApplCallback, errGetApplCallback, [
                ['companyId', chosenCompanyId],
                ['statuses', ['SOCCORSO', 'APPROVED', 'REJECTED', 'EVALUATION', 'SUBMIT']] // 'NDG', 'ADMISSIBLE', 'APPOINTMENT'
            ]);
        }
    }, [chosenCompanyId]);

    const getApplCallback = (data) => {
        if (data.status === 'SUCCESS') {
            if (is(Array, data.data)) {
                setItems(getFormattedBandiData(data.data));
                setStatuses(uniq(items.map(o => o.status)))
                initFilters();
            }
        }
        setLocalAsyncRequest(false);
    }

    const errGetApplCallback = (data) => {
        setLocalAsyncRequest(false);
    }

    const getFormattedBandiData = (data) => {
        return [...(data || [])].map((d) => {
            d.callEndDate = new Date(d.callEndDate);
            d.modifiedDate = new Date(d.modifiedDate);
            d.submissionDate = new Date(d.submissionDate);

            return d;
        });
    };

    const formatDate = (value) => {
        return value.toLocaleDateString('it-IT', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        });
    };

    const clearFilter = () => {
        initFilters();
    };

    const initFilters = () => {
        setFilters({
            global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            callTitle: {
                operator: FilterOperator.AND,
                constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]
            },
            companyName: {
                operator: FilterOperator.AND,
                constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]
            },
            modifiedDate: {
                operator: FilterOperator.AND,
                constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }]
            },
            callEndDate: {
                operator: FilterOperator.AND,
                constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }]
            }
        });
    };

    const renderHeader = () => {
        return (
            <div className="appTableHeader">
                <Button type="button" icon="pi pi-filter-slash" label={__('Pulisci', 'gepafin')} outlined
                        onClick={clearFilter}/>
            </div>
        );
    };

    const dateSubmissionBodyTemplate = (rowData) => {
        return formatDate(rowData.submissionDate);
    };

    const dateFilterTemplate = (options) => {
        return <Calendar value={options.value} onChange={(e) => options.filterCallback(e.value, options.index)}
                         dateFormat="mm/dd/yy" placeholder="mm/dd/yyyy" mask="99/99/9999"/>;
    };

    const statusBodyTemplate = (rowData) => {
        return <ProperBandoLabel status={rowData.status}/>;
    };

    const statusFilterTemplate = (options) => {
        return <Dropdown value={options.value} options={statuses}
                         onChange={(e) => options.filterCallback(e.value, options.index)}
                         itemTemplate={statusItemTemplate} placeholder={translationStrings.selectOneLabel}
                         className="p-column-filter"
                         showClear/>;
    };

    const progressBodyTemplate = (options) => {
        return '-';
    };

    const statusItemTemplate = (option) => {
        return <Tag value={getBandoLabel(option)} severity={getBandoSeverity(option)}/>;
    };

    const actionsBodyTemplate = (rowData) => {
        return <div className="appPageSection__tableActions">
            {rowData.status === 'SOCCORSO'
                ? <Link to={`/domande/${rowData.id}`}>
                    <Button severity="info" label={__('Dettagli', 'gepafin')} icon="pi pi-eye" size="small"
                            iconPos="right"/>
                </Link> : null}
            <Link to={`/domande/${rowData.id}/preview`}>
                <Button severity="info" label={__('Anteprima', 'gepafin')} icon="pi pi-eye" size="small"
                        iconPos="right"/>
            </Link>
        </div>
    }

    const header = renderHeader();

    return (
        <div className="appPageSection__table">
            <DataTable value={items} paginator showGridlines rows={5} loading={localAsyncRequest} dataKey="id"
                       filters={filters} stripedRows removableSort
                       header={header}
                       emptyMessage={translationStrings.emptyMessage}
                       onFilter={(e) => setFilters(e.filters)}>
                <Column field="id" header={__('ID domanda', 'gepafin')}
                        sortable filterPlaceholder={__('Cerca', 'gepafin')}
                        style={{ minWidth: '6rem' }}/>
                <Column field="protocolNumber" header={__('Protocollo', 'gepafin')}
                        sortable filterPlaceholder={__('Cerca', 'gepafin')}
                        style={{ minWidth: '6rem' }}/>
                <Column field="callTitle" header={__('Titolo bando', 'gepafin')}
                        filter sortable
                        filterPlaceholder={__('Cerca il nome', 'gepafin')}
                        style={{ minWidth: '8rem' }}/>
                <Column field="companyName" header={__('Azienda Beneficiaria', 'gepafin')}
                        filter sortable
                        filterPlaceholder={__('Cerca il nome', 'gepafin')}
                        style={{ minWidth: '8rem' }}/>
                <Column header={__('Data di invio', 'gepafin')} filterField="submissionDate" dataType="date"
                        style={{ minWidth: '8rem' }}
                        body={dateSubmissionBodyTemplate} filter filterElement={dateFilterTemplate}/>
                <Column field="status" header={__('Stato', 'gepafin')} filterMenuStyle={{ width: '14rem' }}
                        style={{ minWidth: '7rem' }} body={statusBodyTemplate}
                        filterElement={statusFilterTemplate}/>
                <Column header={__('Esito', 'gepafin')}
                        style={{ minWidth: '7rem' }} field="progress" body={progressBodyTemplate}/>
                <Column header={__('Azioni', 'gepafin')}
                        body={actionsBodyTemplate}/>
            </DataTable>
        </div>
    )
}

export default BeneficiarioDomandeTable;
